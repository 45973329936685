/* Card.module.css */

/* Card.module.css */




/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .container {
    /* Modify your container styles for smaller screens here */
    margin-left: 1rem; /* Adjust margins as needed */
    margin-right: 1rem;
  }

  pre {
    /* Adjust font size and other styles for smaller screens here */
    font-size: 1rem;
    /* Add other styles as needed */
  }
}



.container {
    position: relative;
    // width: 100%;
    width: 40vh;
    height: 50vh;
    background-image: url("../assets/newCard.png"); /* Replace with the actual image path */
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    text-align: center;

    /* Center background image horizontally and vertically */
    // display: flex;
    justify-content: center;
    align-items: center;

    /* Reset margin and padding */
    // margin: 5vh;
    margin-bottom: -10rem;
    // margin-left: vh;
    padding: 0;
  }


  /* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .container {
    /* Modify your container styles for smaller screens here */
    margin-left: 1rem; /* Adjust margins as needed */
    margin-right: 1rem;
  }

  pre {
    /* Adjust font size and other styles for smaller screens here */
    font-size: 1rem;
    /* Add other styles as needed */
  }

}
  
  .text {
    position: absolute;
    color:white;
    top: 10%;
    left: 20%;
    transform: translate(-50%, -50%);
    font-size: 15px;
 
  }
  .handlename
  {
     position:fixed;
      margin-top: 7rem;
      color:white;
      margin-left: 5rem;
      font-weight: bold;
      font-size: 1.2rem;
      font-family: monospace;
  }
  .imgwifi{
    position:fixed;
    margin-top:1.5rem;
    margin-left:24rem,
  }

  .button{
    // margin-left: 20rem;
  
  }

  

.handleDigits{
   position:fixed;
  //  margin-right: 16rem;
    margin-top: 11rem;
    margin-left: 5rem;
    font-weight: bolder;
    font-family: monospace;
}
//fundaccountconfirm
.head{

  margin-left: 45rem;

}
.head1{

  margin-left: 25rem;
}
.button {
  height: 20px;
  width: 20px;
}
#transactI
{
  margin-left: 55rem;
}
#transactI1
{
  margin-left: 53rem;
}
// .container {
//     position: relative;
//     background-position: center center; /* Remove single quotes */
//     width: 50vh;
//     height: 50vh;
//     background-image: url("../assets/newCard.png"); /* Replace with the actual image path */
//     background-size: contain;

//     background-repeat: no-repeat;
//     color: white;
//     text-align: center;
//   }
  
//   .text {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     font-size: 24px;
//   }
  


//Admin

.userList{
//   margin-left: -10rem;
 
//  text-align: center;
//  margin-left:-5rem;
 

}






//Admin Pannel Transaction List Support Button
.buttonsupport{
  margin-left: 15rem;
  
  margin-top: 1.5rem;
  // margin-top: 5px;
  margin-bottom:-30rem;
}


//Admin Pannel Transaction List 
.transaction{
  margin-left:2rem;
  width: 94rem;
  margin-top: 5rem;
}

//viewand comment box on admin pannel
.maincontainer
{
  margin-top: 3.5rem;
}
 userdetails on admin pannel
 .userview{
    // margin-left: -1rem;

    height: 27rem;

 }


//button Approve on admin pannel
.approvebutton{
  margin-left: 1rem;
}

.rejectbutton{
  // margin-right: -3rem;
 }


 //table manage admin pannel 
 .table{
  margin-left: -1rem;
 }


 //Support Container 
 .support{
  width: 79rem;
  position:sticky;
  margin-left: 6rem;
 }


/* Add these styles to ensure the icon stays fixed */
.position-absolute {
  position: relative;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  /* Add your other styles for the dropdown content */
}

/* Show the dropdown content when the button is clicked */
.show {
  display: block;
}

/* Add these styles to fix the position of the icon */
.icon {
  position: absolute;
  top: 0; /* Adjust this value to position the icon vertically as needed */
  right: 0; /* Adjust this value to position the icon horizontally as needed */
}

.toastercontainer
{
  margin-top: 8rem;
 
}

//Enter Ammount On Move
.enterammount{
  margin-left: -1rem;
}

//Identity Image

.fullscreenimage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Is property se image ko screen ke proportion ke hisaab se adjust kiya jaayega */
  z-index: 9999; /* Is property se image ko sabse upar laaya jaayega */
}

//close image
.closebutton {
  position: absolute;
  top: 10px; /* Aap top aur right values ko apne preference ke hisaab se adjust kar sakte hain */
  // right: 10px;
  margin-left: 25rem;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 30px;
  color: white;
  z-index: 10000; /* Image ke upar laane ke liye z-index value ko image ke z-index se bada rakhein */
}
 //confirmationboxapprove
 .confirm{
  margin-left: -10rem;
  
  // margin-bottom: 1rem;
  
 }
 //Box
 .box{
  height: 33rem;
  margin-top:0.2rem
 }
 //suppport List 
 .list{
  margin-left: -10rem;
 }


 .shadowconfirmmove{
  height: 32rem;
 }

 .send{
  height: 35rem;
 }
